const config = {

  // Template-Elemente
  productLagerwareTopAlert: true,
  couponDisplay:true,
  VorkasseDisplay:true,

  // PayPal
  paypal: {
    live: {
      "client-id": 'Aa4vq3TNbFEXQPdVY0SuBYsV6L92as8nuFKlKtCDqNUZwRlLgJSmxCQoSVkycKwYQC85ytZuVTd12eyK',
      //"data-client-token": "abc123xyz==",
    },
    dev: {
      "client-id": 'AXNo2j0t33R20Sdp5bu9ZzbMsyVMSpLoxqPozCky0t6GRRmo3YbCA3PPpFmmKNzMMDD1amQ96etxkoDA',
      //"data-client-token": "abc123xyz==",
    },
  },

  // Footer
  brands: [

    36,  // Joop!
    39, // Cawoe
    200, // Vossen
    166, // Greengate

    160, // The Laundress
    189, // Bondi Wash

    203, // Meraki
    201, // Claus Porto
    190, // Björk & Berries
    // 196, // Le Jacquard Francais
    
  ]

} as const

export default config
